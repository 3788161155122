
@import "src/assets/scss/variables";

.leftbar-container{
    height: 100%;
    width: 18rem;
    margin-right: 10px;
    position: relative;
    transition: width 0.4s cubic-bezier(0.05, 0.74, 0.2, 0.99);
    background: $title-bar-cyan;
    background-image: linear-gradient(180deg, $title-bar-cyan 10%, $dark-cyan 100%);

    .leftbar-content{
        height: 100%;
    }
    .bar-toogle-btn{
        position: absolute;
        top: 1.2rem;
        right: -1.2rem;
        z-index: 1001;
        width: 2rem;
        height: 2rem;
        line-height: 2rem;
        text-align: center;
        color: $primaryColor;
        overflow: hidden;
        border: 1px solid $primaryColor;
        background: #ffffff;
        display: block;
        cursor: pointer;
        outline: none;
        &:focus{
            outline-offset: 0;
            box-shadow: none;
        }
    }

    &.leftbar-hidden{
        width: 0;
        margin-right: 15px;
        .leftbar-content{
            width: 0;
            overflow: hidden;
        }
        .bar-toogle-btn{
            right: -2.0rem;
            transform: rotate(180deg);
        }
    }
}
