@import "../../../assets/scss/variables";

.productMenu {
  padding: 0 10px 10px;

  .menuItems {
    list-style: none;
    padding: 5px 0;
    margin: 0;
    color: rgba(255, 255, 255, 0.8);

    li {
      align-items: center;
      margin: 10px 15px;
      cursor: pointer;
      border-radius: 4px;
      height: 35px;
    }

    li.active {
      font-weight: 700;
    }

    li:hover, li.active {
      background: rgba(255, 255, 255, 0.1);
    }


    li a:focus {
      box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.1);
    }

    a, a:visited {
      text-decoration: none;
      color: white;
      display: flex;
      height: 100%;
      align-items: center;
      padding: 10px;
    }
  ;

    a:active, a.active {
      font-weight: 700;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 4px;
    }
  ;
  }

  .dropUp {
    position: relative;
    display: inline-block;
    padding-top: 7px;
    width: 100%;
  }

  .dropUpContent {
    overflow: hidden;
    bottom: 50px;
    width: 100%;
    z-index: 1;
    transition: all 0.4s ease-out;
  }

  .dropUpContent a {
    padding-left: 25px !important;
    height: 38px !important;
  }
}

.dropBtn {
  background: #0097a7;
  color: white;
  padding: 10px;
  font-size: 14px;
  border: none;

  &:hover {
    cursor: pointer;
  }
}

.show {
  height: 135px;
}

.hide {
  height: 0
}