.chartCard {
  box-shadow: 0 0 5px 2px grey;
  border-radius: 3px;

  .title {
    margin-left: 30px;
  }

  .axisTitle {
    margin-left: 5px;
  }
}