.dropdownFilter {

  .labelText {
    color: white;
    margin: 15px 0 5px;
    white-space: nowrap;
  }

  .dropdownInput {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    height: 37px;
  }
}
