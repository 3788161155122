@import '../../../assets/scss/variables';

.productHomePageWrapper {
  display: flex;
  height: 100%;
  width: 100%;
  background-color: $light-grey;

  .productHomePage {
    width: 100%;
  }
}