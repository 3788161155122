@import '../../assets/scss/variables';

.navigation {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $light-grey;
  height: 67px;
  margin: 0;
  padding: 0 20px;

  .leftSideItems {
    display: flex;
    align-items: center;
    width: 38%;

    .logo {
      height: auto;
      width: 45%;
    }

    .slogan {
      margin-left: 15px;
      font-size: 17px;
      color: $lpButtonColor;
      font-weight: bold;
    }
  }

  .centerSideItems {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;

    .navLink {
      color: #143b6d;
      display: block;
      font-size: 1.25rem;
      font-weight: 600;
      padding: 1em 0.7em;
      text-decoration: none;
    }
  }

  .rightSideItems {
    align-items: center;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    padding-right: 20px;
  }

  .logAuthed {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    padding-right: 20px;

    .auth {
      background: $primaryTextColor;
      border: none;
      color: #143b6d;
      font-size: 1.25rem;
      margin-bottom: 3px;

      &:hover {
        background: $primaryTextColor;
        box-shadow: none;
        color: var(--blue-400);
        outline: none;
      }
    }
  }

  .profileImage {
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.5rem;
    border-radius: 17px;
  }

  .profileName {
    color: $dark-blue;
  }

  .profileItem {
    list-style-type: none;

    button {
      border: none;
      padding: 0.3rem 1rem;
      display: flex;
      align-items: center;
    }
  }
}

#popup_menu {
  width: auto;
}

@media only screen and (max-width: 1200px) {
  .navigation {
    .leftSideItems {
      .slogan {
        font-size: 15px;
      }
    }
  }
}
