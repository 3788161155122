.manageTeamFormDialog {
  width: 40vw;

  .buttonGroup {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    button[type='button'] {
      margin-left: 10px;
    }
  }
}

@media only screen and (max-width: 1800px) {
  .manageTeamFormDialog {
    width: 40vw;
  }
}

@media only screen and (max-width: 1366px) {
  .manageTeamFormDialog {
    width: 43vw;
  }
}

@media only screen and (max-width: 1024px) {
  .manageTeamFormDialog {
    width: 58vw;
  }
}

@media only screen and (max-width: 800px) {
  .manageTeamFormDialog {
    width: 77vw;
  }
}