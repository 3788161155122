.subscriptionList {
  min-width: 500px;
  display: flex;
  flex-direction: column;
  button {
    width: 100%;
    &:before {
      content: '';
      color: red;
    }
  }
}