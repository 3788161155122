.analyticCustomers {
  .tableHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tableLabel {
      display: flex;
      flex-direction: row;
      margin: 0;
      font-size: 1.25rem;

      .tableName {
        margin: 4px 15px 0 0;
        font-weight: 500;
      }
    }
  }

  .updateCustomers {
    display: flex;
    align-items: center;
  }
  .searchBlock {
    display: flex;
  }

  .lastUpdated {
    margin-left: 10px;
    font-size: 16px;
  }

  .exportButton {
    height: fit-content;
    margin-left: 10px;
  }
}

@media only screen and (max-width: 1366px) {
  .analyticCustomers {
    width: 80%
  }
}