.customerInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  .customerForm {
    background: #fff;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    flex: 1 1;
    margin: 0 1rem 1rem;
    overflow-y: auto;
    padding: 1.25rem;
  }
  .buttonGroup {
    padding-right: 0;
    .cancelButton {
      margin-left: 10px;
    }
  }
}