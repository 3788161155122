@import "../../../assets/scss/variables";

.filterControls {
  padding: 25px 0 0;
  .filterButtons{
    display:flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    button{
      width: 49%;
      height: 35px;
    }
    @media only screen and (max-width: 1024px) {
      flex-wrap: wrap;
      button{
        width: 100%;
        margin: 5px 0;
      }
  }
  }
  .labelText {
    margin: 8px 0 5px;
    color: white;
    white-space: nowrap;
   }
}