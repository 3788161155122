@import "../../../assets/scss/variables";

.calendarFilter {
  button.calendarButton {
    text-align: start;
    background: #ffffff;
    display:flex;
    justify-content: center;
    width: 100%;
    height: 37px;
    margin: 0 auto;
    color: $text-grey !important;
    padding: 8px 9px;
    box-shadow: none;
    &:hover {
      background: #ffffff !important;
    }
    &:active {
      background: #ffffff !important;
    }
  }

  .labelText {
    color: white;
    white-space: nowrap;
    margin: 15px 0 5px;
  }
}

button.clearButton {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
