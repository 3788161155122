.analyticKpiPage {
  width: 100%;
  overflow: auto;

  .cardsWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 30px;
    justify-content: center;
    align-items: center;
    padding: 20px;
    .analyticHeader {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;

      .lastUpdated {
        margin-right: 10px;
        font-size: 16px;
      }

      .spinnerWrapper {
        width: 127px;
      }
    }
  }

  .row {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .spinnerStyle {
    position: absolute;
    top: calc(50% - 19px);
    left: calc(50% - 19px);
    color: black;
    font-size: 30px;
  }

  .showElement {
    display: block;
  }

  .hideElement {
    display: none;
  }
}
