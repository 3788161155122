@import '../../../../assets/scss/variables';
.billingDetails {

  .infoWrapper {
    border: 2px solid $light-grey;
    border-radius: 10px;
    margin: 10px 20px;
    padding: 20px;
    font-size: 18px;

    .card {
      display: flex;
      align-items: center;
      border: 2px solid $light-grey;
      border-radius: 10px;
      width: 320px;
      height: 100px;
      margin: 15px 0 0 20px;
      padding: 10px;

      .imageWrapper {
        background-color: $light-grey;
        border-radius: 3px;
        width: 30%;
        aspect-ratio: 1.55;
        background-size: cover;
      }

      .visa {
        background-position: 100% 0;
        background-image: url("../../../../assets/img/payment-logo.png");
      }

      .mastercard {
        background-position: 0 100%;
        background-image: url("../../../../assets/img/payment-logo.png");
      }

      .info {
        margin-left: 20px;
      }

    }

    .item {
      margin: 10px;
    }

    .simpleText {
      font-size: 14px;
    }
  }
}