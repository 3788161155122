$gutter: 1rem;
$headerHeight: 67px;

@import './assets/scss/variables';
@import './assets/scss/fonts';
@use 'node_modules/primeflex/primeflex.scss';

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  min-width: 768px;
  min-height: 500px;
  font-size: $fontSize;
  font-family: $fontFamily;
}

$scrollSize: 7px;
::-webkit-scrollbar {
  width: $scrollSize;
  height: $scrollSize;
}

::-webkit-scrollbar-track {
  background: $primaryTextColor;
  border-radius: $scrollSize;
}

::-webkit-scrollbar-thumb {
  background: $primaryDarkColor;
  border-radius: $scrollSize;
}

::-webkit-scrollbar-thumb:hover {
  background: $primaryDarkerColor;
}

body {
  .overlay_panel .p-overlaypanel-content .p-listbox {
    border: none !important;
    width: inherit !important;
  }

  .auth0-lock-container .auth0-lock-header-logo {
    width: 100% !important;
  }

  .p-dialog-mask {
    .p-datatable-scrollable-body {
      .p-row-editor-init.p-link {
        border-radius: 50%;
        height: 2.357rem;
        width: 2.357rem;
        color: $primaryTextColor;
        background: $editorColor;
        border: 1px solid $editorColor;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.14);
      }

      .p-link.p-row-editor-init:enabled:hover {
        color: $primaryTextColor;
        background: $editorColor;
      }

      .p-button-rounded.p-button-warning.p-button-icon-only {
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.14);
      }
    }

    .confirmation-content {
      display: flex;
      flex-direction: row;
      align-items: center;

      i {
        font-size: 2rem;
        margin-right: 10px;
      }
    }
  }

  .p-panel {
    .p-panel-content {
      background: rgba(255, 255, 255, 0.8);
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    .p-panel-header {
      background: rgba(255, 255, 255, 0.8);
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      font-size: 18px;
    }
  }
}

.p-galleria-enter-done {
  width: 550px !important;
}

.p-galleria-content {
  width: 85% !important;
}

#root {
  height: 100% !important;
}

.app {
  height: 100%;
  overflow: hidden;

  .top-nav {
    height: $headerHeight;
    margin: 0;
    border-bottom: 1px solid $light-grey;
  }

  .content {
    display: flex;
    flex-direction: row;
    height: calc(100% - #{$headerHeight});
  }

  .pageWrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .list-generic {
    flex: 1 1;
    height: 100%;
    overflow: hidden;
    position: relative;

    .table-generic {
      position: relative;
      border: solid 1px lightgray;
      border-radius: 4px;
      margin: 0 1rem;
      height: calc(100% - 100px);

      &.with-paginator {
        height: calc(100% - 170px);
      }
    }
  }

  .side-filters {
    padding: 0 10px 5px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .filtersInputs {
      display: flex;
      flex: 1;
      flex-direction: column;
      overflow-y: auto;
      min-height: min-content;
      padding-right: 5px;
      margin-right: -5px;
    }
  }

  .p-datatable-scrollable-wrapper {
    height: 100%;
  }

  .p-datatable-scrollable-view {
    height: 100%;
  }

  .p-datatable-scrollable-body {
    height: calc(100% - 100px);

    .p-row-editor-init.p-link {
      border-radius: 50%;
      height: 2.357rem;
      width: 2.357rem;
      color: $primaryTextColor;
      background: $editorColor;
      border: 1px solid $editorColor;
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.14);
    }

    .p-link.p-row-editor-init:enabled:hover {
      color: $primaryTextColor;
      background: $editorColor;
    }

    .p-button-rounded.p-button-warning.p-button-icon-only {
      box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2), 0 3px 4px rgba(0, 0, 0, 0.14);
    }
  }

  .p-datatable-unfrozen-view .p-rowgroup-header-name {
    visibility: hidden;
  }

  .p-splitbutton-defaultbutton,
  .p-splitbutton-menubutton {
    background: $title-bar-cyan;
    font-size: 30px;
    color: $dark-blue;
    border: none;
  }

  .p-multiselect-label {
    color: $text-grey;
  }

  .p-multiselect .p-multiselect-label.p-placeholder {
    color: $text-grey;
    font-weight: initial;
  }

  .p-button-icon-only.p-datepicker-trigger {
    background-color: white;
    border: 1px solid lightgray;
  }

  .p-button.p-button-info {
    background: $buttonInfoColor;
    border: 1px solid $buttonInfoColor;
  }

  .p-button-rounded.p-button-info.p-button-outlined.p-button-icon-only {
    background-color: transparent;
    color: $editorColor;
    border: 1px solid;
  }

  .p-button.p-button-success {
    background: $buttonSuccessColor;
    border: 1px solid $buttonSuccessColor;
  }

  .p-button.p-button-danger {
    background: $buttonDangerColor;
    border: 1px solid $buttonDangerColor;
  }

  .p-button.p-button-help {
    background: $buttonHelpColor;
    border: 1px solid $buttonHelpColor;
  }

  .p-datatable-scrollable-body,
  .p-datatable-scrollable-header,
  .p-datatable-scrollable-footer {
    width: auto !important;
  }

  .p-datatable.p-datatable-sm .p-datatable-header {
    padding: 8px 10px;
  }

  .p-datatable .p-datatable-thead > tr > th {
    font-weight: 500;
  }

  .p-progress-spinner {
    position: relative;
    margin: auto;
    width: 150px;
    height: 150px;
    display: flex;
  }

  .p-rowgroup-header {
    background: $lighter-grey !important;
  }

  .p-breadcrumb .p-menuitem-text:hover {
    text-decoration: underline;
  }

  .generic-list-message {
    position: absolute;
    top: calc(50% + 35px);
    left: 0;
    right: 0;
    overflow-wrap: anywhere;
    margin-left: 1rem;
    margin-right: 1rem;

    &.export,
    &.import {
      top: 45%;
      margin-left: 15rem;
    }

    h2,
    h3 {
      color: $text-grey;
    }

    .p-text-center {
      text-align: center;
    }
  }
}

#popup_menu {
  width: auto;
}

div.p-datatable-wrapper {
  height: 100%;
}

.table-generic.table-with-header.p-datatable-sm > div.p-datatable-wrapper {
  height: calc(100% - 52px);
}

a:active, a.active {
  color: $light-blue !important;
}

@media only screen and (max-width: 992px) {
  .App {
    width: 100vw;
    height: 100%;
  }
}
