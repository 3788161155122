.infoCard {
  box-shadow: 0 0 5px 2px grey;
  border-radius: 3px;
  width: calc(33% - 10px);
  min-height: 100px;

  .header {
    margin: 10px 0;
    text-align: center;
  }

  .subInfo {
    background-color: rgb(217, 214, 214);
    border-radius: 3px;
    display: inline-block;
    width: calc(50% - 6px);
    margin: 3px;

    .subInfoText {
      color: #006e8c;
      text-align: center;
    }
  }
}