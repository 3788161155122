@import "../../../assets/scss/variables";

.superUsersList {
  width: 100%;
  padding: 10px;

  .linkStyle {
    cursor: pointer;
    color: $light-blue;
    text-decoration: underline;
  }

  .linkStyle:hover {
    text-decoration: none;
  }

  .tableHeader {
    display: flex;
    justify-content: flex-end;
  }

  .footerMessage {
    color: $text-grey;
  }
}