@import '../../../../assets/scss/variables';

.billingHistoryList {
  .downloadLink {
    color: $buttonDangerColor;

    &:focus,
    &:hover,
    &:active {
      color: $buttonDangerColor !important;
    }

    i {
      font-size: 25px;
    }
  }

  .disabledLink {
    opacity: 0.5;
  }
}