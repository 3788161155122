$fontSize: 14px !default;
$fontFamily: 'Nunito', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Barcode-Font', 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji' !default;
$transitionDuration: 0.2s !default;
$animationDuration: 0.4s !default;
$animationTimingFunction: cubic-bezier(0.05, 0.74, 0.2, 0.99) !default;
$letterSpacing: normal !default;
$borderRadius: 4px !default;
$tabletBreakpoint: 991px !default;
$phoneBreakpoint: 576px !default;

$dark-blue: #143b6d;
$base-blue: #006e97;
$dark-cyan: #00838f;
$title-bar-cyan: #0097a7;
$light-blue: var(--blue-400);
$lighter-blue: #cee5f2;
$base-green: #58857d;
$base-purple: #a090bf;
$base-navy-blue: #39637f;
$base-brown: #ad8265;
$base-grey: #4c4c4c;
$text-grey: var(--text-color);
$light-grey: #dee2e6;
$lighter-grey: #f8f9fa;

$primaryColor: #00bcd4;
$primaryLightColor: scale-color($primaryColor, $lightness: 60%) !default;
$primaryDarkColor: scale-color($primaryColor, $lightness: -10%) !default;
$primaryDarkerColor: scale-color($primaryColor, $lightness: -20%) !default;
$primaryTextColor: #ffffff;

$highlightBg: #e0f7fa;
$highlightTextColor: #495057;

$lpParagraphColor: #606060;
$lpHeaderColor: #021f54;
$lpButtonColor: #41772a;
$lpShadowColor: #3b4148ff;
$lpGreyColor: #606060ff;
$lpLightColor: #ffffff;
$lpGoldenColor: #fbe211ff;
$lpBackgroundColor: #61a5f1ff;
$lpSetupColor: #de4383;
$lpCropsColor: #774898;
$lpMobileColor: #f3ae4b;
$shareColor: #80ba50;
$visualColor: #00a8b5;
$supportHeaderColor: #006e8c;
$supportColor: #232323;
$editorColor: #2196f3;
$buttonDangerColor: #e86a4a;
$buttonHelpColor: #9d3fc2;
$buttonSuccessColor: #98c355;
$buttonInfoColor: #7ebdfc;

$width-xs: 576px;
$width-sm: 768px;
$width-md: 993px;
$width-lg: 1024px;
$width-xl: 1366px;
$width-xxl: 1800px;
$addDialog-width-xxl: 40vw;
$addDialog-width-xl: 43vw;
$addDialog-width-lg: 58vw;
$addDialog-width-md: 77vw;
$refTable-width: 35vw;
$refTable-width-xl: 32vw;
$refTable-width-lg: 45vw;
$deleteDialog-width: 450px;
