@import "../../../assets/scss/variables";

.dialog {
  min-width: 500px;
  .wrapper {
    padding: 10px;
    border: 1px solid grey;
    border-radius: 3px;
    .productsCaption {
      font-size: 16px;
      font-weight: 500;
    }
    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      a {
        text-decoration: none;
        padding: 5px 10px;

        &:hover {
          background-color: $lighter-grey;
        }

      }
    }
  }
}