@import "../../../assets/scss/variables";

.manageTeamList {

  .tableHeader {
    display: flex;
    justify-content: space-between;
  }

  .linkStyle {
    cursor: pointer;
    color: $light-blue;
    text-decoration: underline;
    border: none;
    background: transparent;
  }
}