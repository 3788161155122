@import '../../../../assets/scss/variables';
.subscriptionInfo {

  .infoWrapper {
    border: 2px solid $light-grey;
    border-radius: 10px;
    margin: 10px 20px;
    padding: 20px;
    font-size: 18px;

    .card {
      display: flex;
      align-items: flex-start;
      border: 2px solid $light-grey;
      border-radius: 10px;
      width: 500px;
      height: 200px;
      margin: 15px 0 0 20px;
      padding: 10px;

      .imageWrapper {
        border-radius: 3px;
        background-repeat: no-repeat;
        width: 45%;
        aspect-ratio: 3.8;
        background-size: contain;
        background-position: 100% 0;
        background-image: url("../../../../assets/img/logo-horiz.png");
      }

      .left {
        flex: 2;
      }

      .right {
        display: flex;
        flex: 1;
        align-items: flex-end;
        justify-content: flex-end;
        height: 100%;
        padding-left: 15px;
      }
      .info {
        margin-top: 20px;
      }

    }

    .header {
      margin-left: 30px;
      font-size: 18px;
    }

    .simpleText {
      margin: 5px 0 5px 0;
      font-size: 18px;
    }
  }
}